#toast-container>div {
  opacity: 1;
}

.toast-container .ngx-toastr {
  border-radius: 15px;
  width: 345px;
}

.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important;
}

.toast-success {
  background-color: white !important;
  border-top: 2px solid #51A351;
  color: #51A351 !important;
}

.toast-error {
  background-color: white !important;
  border-top: 2px solid #BD362F;
  color: #BD362F !important;
  // background-color: #BD362F !important;
}

.toast-info {
  background-color: #2F96B4 !important;
}

.toast-warning {
  background-color: #F89406 !important;
}

@media screen and (max-width: 767px) {
  .toast-container .alert {
    width: 91.6666%;
  }
}
