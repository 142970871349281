/*!

=========================================================
* Paper Dashboard Angular - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-angular
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-angular/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@import "paper-dashboard/variables";
@import "paper-dashboard/mixins";

// Plugins CSS
@import "paper-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "paper-dashboard/plugins/plugin-perfect-scrollbar";

// Core CSS
@import "paper-dashboard/buttons";
@import "paper-dashboard/inputs";
@import "paper-dashboard/typography";
@import "paper-dashboard/misc";
@import "paper-dashboard/checkboxes-radio";
@import "paper-dashboard/verticleStepper";

// components
@import "paper-dashboard/navbar";
@import "paper-dashboard/page-header";
@import "paper-dashboard/dropdown";
@import "paper-dashboard/alerts";
@import "paper-dashboard/images";
@import "paper-dashboard/nucleo-outline";
@import "paper-dashboard/tables";
@import "paper-dashboard/sidebar-and-main-panel";
@import "paper-dashboard/footers";
@import "paper-dashboard/fixed-plugin";

// cards
@import "paper-dashboard/cards";
@import "paper-dashboard/cards/card-plain";
@import "paper-dashboard/cards/card-chart";
@import "paper-dashboard/cards/card-user";
@import "paper-dashboard/cards/card-map";
@import "paper-dashboard/cards/card-stats";

// angular differences
@import "paper-dashboard/angular/plugin-ngx-toastr";
@import "paper-dashboard/angular/sidebar-and-main-panel";
@import "paper-dashboard/angular/dropdown";
@import "paper-dashboard/angular/inputs";
@import "paper-dashboard/angular/fixed-plugin";

@import "paper-dashboard/responsive";

/**  =====================
      Authentication css start
==========================  **/

/* finally, import Bootstrap */

section {
  margin-top: 3%;
}
.pointer {
  cursor: pointer;
}
.auth-wrapper {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  background: #4680ff;
}

@media (min-width: 1200px) {
  .auth-wrapper .container {
    max-width: 1277px !important;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper {
    display: block;
    margin: 0 auto;
  }

  .auth-wrapper > .row {
    min-height: 100vh;
  }

  .auth-wrapper > .row > .aut-bg-img {
    min-height: 100vh;
  }
}

.auth-wrapper .saprator {
  position: relative;
  margin: 8px 0;
}

.auth-wrapper .saprator span {
  background: #fff;
  position: relative;
  padding: 0 10px;
  z-index: 5;
  font-size: 20px;
}

.auth-wrapper .saprator:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e2e5e8;
  z-index: 1;
}

.auth-wrapper a,
.auth-wrapper p > a {
  color: #37474f;
  font-weight: 600;
}

.auth-wrapper .input-group {
  background: transparent;
}

.auth-wrapper .card {
  margin-bottom: 0;
  padding: 8px;
}

.auth-wrapper .card .card-body {
  padding: 20px 25px 20px 40px;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper .card {
    display: block;
  }
}

.auth-wrapper > div {
  z-index: 5;
}

.auth-wrapper .auth-content {
  position: relative;
  padding: 15px;
  z-index: 5;
}

.auth-wrapper .auth-content:not(.container) {
  width: 500px;
}

.auth-wrapper .auth-content:not(.container) .card-body {
  padding: 40px 35px;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper .auth-content {
    margin: 0 auto;
  }
}

.auth-wrapper .auth-side-img {
  padding-right: 400px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-box-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .auth-wrapper .auth-side-img {
    display: none;
  }
}

.auth-wrapper .auth-side-form {
  position: relative;
  min-height: 100%;
  background: #fff;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  left: auto;
  float: right;
}

.auth-wrapper .auth-side-form > * {
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 991px) {
  .auth-wrapper .auth-side-form {
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .auth-wrapper .auth-side-form .auth-content:not(.container) {
    max-width: 350px;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .auth-wrapper.aut-bg-img .auth-side-form {
    height: 100vh;
  }
}

@media only screen and (max-width: 767px) {
  .auth-wrapper .card .card-body {
    padding: 30px 15px;
  }
}

.auth-wrapper.offline {
  background-image: none;
}

.auth-wrapper.offline h1 {
  font-size: 87px;
  font-weight: 700;
}

.auth-wrapper.offline:before {
  display: none;
}

.auth-wrapper .card-body .carousel-indicators {
  margin: 15px 0 10px;
  bottom: 0;
}

.auth-wrapper .card-body .carousel-indicators li {
  width: 50px;
  background-color: rgba(70, 128, 255, 0.4);
  border-radius: 5px;
  height: 4px;
}

.auth-wrapper .card-body .carousel-indicators li.active {
  background-color: #4680ff;
}

.auth-wrapper .img-logo-overlay {
  position: absolute;
  top: 40px;
  left: 50px;
}

/* image varient start */
.aut-bg-img {
  // background-image: url("../img/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.aut-bg-img .auth-content {
  padding: 70px 40px;
}

.btn-outline-primary,
.btn-primary {
  border-radius: 20px;
}

.add-icon {
  float: right;
  font-size: 22px;
  color: var(--primary);
  cursor: pointer;
}
.add-trash {
  float: right;
  font-size: 22px;
  color: var(--danger);
  cursor: pointer;
}
.container {
  margin-top: 9rem;
  padding: 0px 9px;
  // max-width: 1277px !important;
  //max-width: 100%
}
.break-line {
  font-family: 'Poppins', sans-serif;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  background: #ffffff;
  font-weight: 300;
  border: none;
  line-height: 14px;
  font-size: 12px;
}

/* image varient End */
/*====== Authentication css end ======*/

/* ======= Toaster ======== */
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 750px;
    margin: 1.75rem auto;
    margin-top: 2%;
  }
}
::-webkit-scrollbar {
  width: 7px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background:rgb(184, 181, 176); /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #6c757d; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}


.selectDisplay {
  font-size: 14px;
  font-family: "poppins";
  letter-spacing: 0.13px;
  color: #000;
  opacity: 1;
  margin-bottom: 4px;
  font-weight: 500;
  margin-left: 25px;
  
}
.aaa {
  width: 24px;
  text-align: center;
}