/* You can add global styles to this file, and also import other style files */
@import "~ngx-toastr/toastr.css";
@import "./node_modules/bootstrap/scss/bootstrap.scss";
@import "~bs-stepper/dist/css/bs-stepper.min.css";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
// @import "~@ctrl/ngx-emoji-mart/picker";

// importing fonts 
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;600&family=Roboto:wght@500&display=swap');
html, body { height: calc(100vh - 44px) !important; }
body { 
  margin: 0; 
  // font-family: Roboto, "Helvetica Neue", sans-serif; 
  font-family: 'Poppins', sans-serif;
  zoom: 90%;
}
.backdropBackground {
    background: transparent linear-gradient(122deg, #d5d5dc66 0%, #d5d5dc30 100%)
      0% 0% no-repeat padding-box;
    box-shadow: 15px 15px 50px #ffffff29;
    border: 1px solid #ffffff40;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }
  .navbarColor{
    background-color: #FFFFFF;  
   }
  @media (min-width: 1200px) {
    .auth-wrapper .container {
      max-width: 1280px !important;
    }

    .disclaimer{
      display: none !important
  }
}
::placeholder{
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color:#D5D5DC;
  opacity: 1;
}
*{
  font-family: 'Poppins','sans-serif';
}

.read-more-sheet2{
  width: 1248px;
height: 810px;
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px -10px 10px #00000029;
opacity: 1;
}

.fade {
  opacity: inherit;
  transition: opacity .15s linear;
}

.style-success {
  background-color: #ffffff;
  font-family: 'Poppins';
  color: #000000;
}
.style-success .mat-simple-snackbar-action  {
  color: green;
}
.style-error {
  background-color: #ffffff;
  font-family: 'Poppins';
  color: #000000;
}
.style-error .mat-simple-snackbar-action {
  color: red;
}

.style-warn {
  background-color: #ffffff;
  font-family: 'Poppins';
  color: #000000;
}
.style-warn .mat-simple-snackbar-action {
  color: orange;
}

