.events .list {
  display: flex;
  color: var(--primary);
  padding: 0px !important;
}

.events time {
  position: relative;
}

.events time::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 0;
  top: 7px;
  transform: translateX(60%);
  border-radius: 50%;
  background: var(--primary);
  border: 2px #ccc solid;
  width: 0.7em;
  height: 0.7em;
}

.events span {
  padding: 0 1.5em 0.5em 1.5em;
  position: relative;
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
  border-left: 2px #ccc solid;
  top: 18px;
}

.events strong {
  display: block;
  font-weight: bolder;
}

.events {
  margin: 1em;
}
.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
}
.main {
  padding-inline-start: 0px !important;
  margin-block-start: 0px !important;
}
.dsplay-flex {
  display: inline-flex;
}
ul {
  padding-inline-start: 0px;
  margin-block-start: 0px;
}
